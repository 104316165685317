<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Transports Systems</h4>
                            </div>
                            <div class="col-md-9">
                            </div>
                            <div class="col-md-1">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="bikerData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item }">
                                    <b-button class="actionBut" v-b-tooltip.hover title="View"  variant="info" @click="view(item)">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Edit" variant="success" @click="edit(item)">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Delete" v-b-modal.modal-delete variant="danger" @click="deleteItem(item)">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal hide-footer title="Delete Transport System" ref="modal-delete" id="modal-delete">
                            <p>Do yo really want to delete this Transport System ?</p>
                            <div style="display: flex; justify-content: flex-end">
                                <b-button class="mr-2" variant="outline-danger" @click="hide">Cancel</b-button>
                                <b-button variant="outline-primary" @click="deleteBike">Delete</b-button>
                            </div>
                        </b-modal>

                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>    
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import { tableData } from "./dataBiker";
    import {axios_delete, axios_get} from "../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Transports Systems",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                bikerData:[],
                tableData: tableData,
                delete: null,
                user: null,
                loader: false,
                title: "View all Transports Systems",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "View Transports Systems",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                fields: [
                    { key: "reference", sortable: true },
                    { key: "name", sortable: true },
                    { key: "surname", sortable: true },
                    { key: "number", sortable: true },
                    { key: "email", sortable: true },
                    { key: "coverage_area", sortable: true },
                    { key: "actions", sortable: true },
                ],

            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
            this.user=JSON.parse(localStorage.getItem('user'))
            axios_get("/bikers",{},(d)=>{
                    this.bikerData=d.sort((a, b)=>{
                        if(a.name <= b.name) return -1;
                        else return 1;
                    })
                    this.bikerData=this.bikerData.filter(e=>{return e.country_id==this.user.country_id})
                    console.log(d)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );

        },
        methods: {
            // editItem(item) { console.log(item); },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            edit(e){
                console.log(e)
                window.location.href = "edit-biker/"+e.id
            },
            view(e){
                console.log(e)
                window.location.href = "/biker/view-biker/"+e.id
            },
            deleteItem(e){
                this.deleteItem = e;
            },
            hide(){
                this.$refs['modal-delete'].hide()
            },
            deleteBike(){
                this.loader = true
                this.hide()
                axios_delete('/bikers/'+this.deleteItem.id, {}, ()=>{
                    axios_get("/bikers",{},(d)=>{
                            this.bikerData=d.sort((a, b)=>{
                                if(a.name <= b.name) return -1;
                                else return 1;
                            })
                            this.bikerData=this.bikerData.filter(e=>{return e.country_id==this.user.country_id})
                            console.log(d)
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                        }
                    );
                }, (e)=>{
                    console.log('error', e)
                }, ()=>{
                    console.log('final')
                    this.loader = false
                })
            }
        },
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;

    }
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>
