const tableData = [
  {
    reference: '0001',
    name: 'Ndungla',
    surname: 'Joel',
    email: 'ndunglaj@gmail.com',
    telephone: '6758695472',
    coverageArea: 'Yaounde-Douala',
  },
  {
    reference: '0001',
    name: 'Ndungla',
    surname: 'Joel',
    email: 'ndunglaj@gmail.com',
    telephone: '6758695472',
    coverageArea: 'Yaounde-Douala'
  },
  {
    reference: '0001',
    name: 'Ndungla',
    surname: 'Joel',
    email: 'ndunglaj@gmail.com',
    telephone: '6758695472',
    coverageArea: 'Yaounde-Douala'
  },
  {
    reference: '0001',
    name: 'Ndungla',
    surname: 'Joel',
    email: 'ndunglaj@gmail.com',
    telephone: '6758695472',
    coverageArea: 'Yaounde-Douala'
  },
  {
    reference: '0001',
    name: 'Ndungla',
    surname: 'Joel',
    email: 'ndunglaj@gmail.com',
    telephone: '6758695472',
    coverageArea: 'Yaounde-Douala'
  },

];

export { tableData };
